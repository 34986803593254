// @import "./sass/variables";
@import "./sass/theme/style.scss";

.invalid-feedback {
  text-align: left;

  &::first-letter {
    text-transform: capitalize;
  }
}

.modal {
  pointer-events: none;
}

.modal-overlay {
  z-index: 110;
}

.bg-fake-input {
  background-color: $gray-100;
}

.mui-filled-input-bg {
  border-radius: 0.475rem;
  background-color: #f5f8fa;
}

.container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.imgWrapper {
  width: 100%;
  height: 100%;
}

.dotWrapper {
  position: absolute;
  bottom: 50px;

  button {
    padding: 0;
    width: 10px;
    height: 10px;
    border: unset;
    border-radius: 50px;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.page {
  position: absolute;
  width: 100%;
  height: 100%;
  will-change: transform;
  overflow: hidden;
}

.page > div {
  touch-action: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  will-change: transform;
  box-shadow: 0 62.5px 125px -25px rgba(50, 50, 73, 0.5), 0 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);
}

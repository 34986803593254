$rt-namespace: "Toastify";
$rt-toast-width: 320px !default;
$rt-toast-background: #fff !default;
$rt-toast-min-height: 45px !default;
$rt-toast-max-height: 800px !default;

$rt-color-default: #fff !default;
$rt-color-dark: #121212 !default;
// $rt-color-info: $primary-light;
$rt-color-success: #07bc0c !default;
$rt-color-warning: #f1c40f !default;
$rt-color-error: #e74c3c !default;

$rt-text-color-default: #aaa !default;
$rt-text-color-dark: #fff !default;

$rt-color-progress-default: linear-gradient(
  to right,
  #4cd964,
  #5ac8fa,
  #007aff,
  #34aadc,
  #5856d6,
  #ff2d55
) !default;
$rt-color-progress-dark: #bb86fc !default;
$rt-mobile: "only screen and (max-width : 480px)" !default;
$rt-z-index: 9999 !default;

.#{$rt-namespace}__toast-container {
  z-index: $rt-z-index;
  transform: translate3d(0, 0, #{$rt-z-index}px);
  position: fixed;
  padding: 4px;
  width: $rt-toast-width;
  box-sizing: border-box;
  color: #fff;
  &--top-left {
    top: 1em;
    left: 1em;
  }
  &--top-center {
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
  }
  &--top-right {
    top: 1em;
    right: 1em;
  }
  &--bottom-left {
    bottom: 1em;
    left: 1em;
  }
  &--bottom-center {
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
  }
  &--bottom-right {
    bottom: 40px;
    right: 34px;
  }
}

@media #{$rt-mobile} {
  .#{$rt-namespace}__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
    &--top-left,
    &--top-center,
    &--top-right {
      top: 0;
      transform: translateX(0);
    }
    &--bottom-left,
    &--bottom-center,
    &--bottom-right {
      bottom: 0;
      transform: translateX(0);
    }
    &--rtl {
      right: 0;
      left: initial;
    }
  }
}

.#{$rt-namespace}__toast {
  position: relative;
  min-height: $rt-toast-min-height;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 1rem 0.875rem;
  border-radius: $alert-border-radius;
  display: flex;
  justify-content: space-between;
  max-height: $rt-toast-max-height;
  overflow: hidden;
  font-size: 1.1675rem;
  cursor: pointer;
  direction: ltr;
  &--rtl {
    direction: rtl;
  }
  &--dark {
    background-color: $rt-color-dark;
    color: $rt-text-color-dark;
  }
  &--default {
    background-color: $rt-color-default;
    color: $rt-text-color-default;
  }
  &--info {
    background-color: $primary-light;
    color: $primary;
  }
  &--success {
    background-color: $success-light;
    color: $success;
  }
  &--warning {
    background-color: $warning-light;
    color: $warning;
  }
  &--error {
    background-color: $danger-light;
    color: $danger;
  }
  &-body {
    margin: auto 0;
    flex: 1 1 auto;
    padding-right: 0.5rem;
  }
}

.#{$rt-namespace}--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

@media #{$rt-mobile} {
  .#{$rt-namespace}__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}

.#{$rt-namespace}__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;

  &--default {
    color: #000;
    opacity: 0.3;
  }
  &--info {
    color: $primary;
  }
  &--warning {
    color: $warning;
  }
  &--success {
    color: $success;
  }
  &--error {
    color: $danger;
  }

  & > svg {
    fill: currentColor;
    height: 16px;
    width: 14px;
  }

  &:hover,
  &:focus {
    opacity: 1;
  }
}

@keyframes #{$rt-namespace}__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

.#{$rt-namespace}__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: $rt-z-index;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left;

  &--animated {
    animation: #{$rt-namespace}__trackProgress linear 1 forwards;
  }

  &--controlled {
    transition: transform 0.2s;
  }

  &--rtl {
    right: 0;
    left: initial;
    transform-origin: right;
  }

  &--default {
    background: $rt-color-progress-default;
  }

  &--dark {
    background: $rt-color-progress-dark;
  }

  &--info {
    background-color: darken($primary-light, 10%);
  }
  &--success {
    background-color: darken($success-light, 10%);
  }
  &--warning {
    background-color: darken($warning-light, 10%);
  }
  &--error {
    background-color: darken($danger-light, 10%);
  }
}
// entrance and exit animations
@import "~react-toastify/scss/animations/slide";

//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
$primary:       									#009EF7;
$primary-active:    								#0095E8;
$primary-light:    									#ECF8FF;
$primary-inverse:  									#FFFFFF;

// Success
$success:       									#50CD89;
$success-active:    								#47BE7D;
$success-light:    									#E8FFF3;
$success-inverse:  									#FFFFFF;

// Info
$info:       									    #7239EA;
$info-active:    									#5014D0;
$info-light:    									#F8F5FF;
$info-inverse:  									#FFFFFF;

// Danger
$danger:       									    #F1416C;
$danger-active:    									#D9214E;
$danger-light:    									#FFF5F8;
$danger-inverse:  									#FFFFFF;

// Warning
$warning:       									#FFC700;
$warning-active:    								#F1BC00;
$warning-light:    									#FFF8DD;
$warning-inverse:  									#FFFFFF;

// Card Box Shadow
$card-box-shadow:                                   0px 0px 20px 0px rgba(76,87,125,0.02);

// Forms
//$form-label-font-weight:                          600;
//$form-label-font-size:                            1.05rem;

//$input-btn-font-weight                            500;
//$input-btn-font-size:                             1rem;                               
//$input-btn-font-size-sm:                          0.95rem;
//$input-btn-font-size-lg:                          1.1rem;

// Buttons
//$btn-font-weight:                                 $input-btn-font-weight;
//$btn-font-size:                                   $input-btn-font-size;
//$btn-font-size-sm:                                $input-btn-font-size-sm;
//$btn-font-size-lg:                                $input-btn-font-size-lg;

// Inputs
//$input-font-weight:                               $input-btn-font-weight;
//$input-font-size:                                 $input-btn-font-size;
//$input-font-size-sm:                              $input-btn-font-size-sm;
//$input-font-size-lg:                              $input-btn-font-size-lg;
//
// DataTables
//

div.dataTables_wrapper div.dataTables_length {
  padding: 0.5rem 0;
}

div.dataTables_wrapper div.dataTables_filter {
  padding: 0.5rem 0;
}

div.dataTables_wrapper div.dataTables_info {
  font-weight: $font-weight-bold;
  color: $gray-700;
  padding: 0.5rem 0;
}

div.dataTables_length + div.dataTables_info {
  margin-left: 1rem;
}

// Pagination
div.dataTables_wrapper div.dataTables_paginate {
  padding: 0.5rem 0;
  margin-left: 0.5rem;

  .pagination {
    margin: 0;
  }
}

// Sorting

table.dataTable > thead > tr > td:not(.sorting_disabled),
table.dataTable > thead > tr > th:not(.sorting_disabled) {
  padding-right: 30px;
}
table.dataTable > thead .sorting,
table.dataTable > thead .sorting_asc,
table.dataTable > thead .sorting_asc_disabled,
table.dataTable > thead .sorting_desc,
table.dataTable > thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}
table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_desc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:before {
  position: absolute;
  bottom: 0.5em;
  display: block;
  opacity: 0.3;
}
table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_desc_disabled:before {
  right: 1em;
  content: "↑";
}
table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "↓";
}
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:after {
  opacity: 1;
}
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:after {
  opacity: 0;
}

table.dataTable > thead > tr > td:not(.sorting_disabled),
table.dataTable > thead > tr > th:not(.sorting_disabled) {
  padding-right: 0;
}

table.dataTable > thead .sorting_asc,
table.dataTable > thead .sorting_desc {
  vertical-align: middle;
}

table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_desc:before {
  position: relative;
  opacity: 0;
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  content: " ";
  bottom: auto;
  right: auto;
  left: auto;
  margin-left: 0.5rem;
}

table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:before {
  display: none !important;
}

table.dataTable > thead .sorting_asc:after {
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  @include svg-bg-icon(arrow-top, $text-muted);
}

table.dataTable > thead .sorting_desc:after {
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  @include svg-bg-icon(arrow-bottom, $text-muted);
}

.root {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  height: 2px;
  background-color: var(--bs-primary);
  animation-duration: 1000ms;
  animation-name: loadingBar;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  transform-origin: center;
}

@keyframes loadingBar {
  0% {
    transform: scaleX(0);
  }

  25% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scaleX(1);
  }
}
